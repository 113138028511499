<template>
  <div class="view">
    <v-toolbar class="my-bar">
      <v-text-field
        v-model="search"
        hide-details
        prepend-inner-icon="search"
        single-line
        dense
        filled
        rounded
        clearable
        @click:clear="clearSearch"
      ></v-text-field>
      <v-btn v-if="rightFilter('w')" icon @click="addItem">
        <v-icon>add</v-icon>
      </v-btn>
      <v-btn v-if="rightFilter('w')" icon :disabled="!item" @click="editItem">
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn v-if="rightFilter('d')" icon :disabled="!item" @click="deleteItem">
        <v-icon>remove</v-icon>
      </v-btn>
      <v-spacer />
      <v-dialog v-model="dialog" max-width="500px" scrollable>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.id"
                    readonly
                    label="id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.name"
                    label="Наименование"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedItem.activeTo"
                    label="Действительно до"
                    type="date"
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">
              Отмена
            </v-btn>
            <SaveButton
            color="primary"
            :handler="save"
            content="OK"
            >

            </SaveButton>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Вы точно хотите удалить услугу?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDelete">Отмена</v-btn>
            <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn icon @click="$parent.selected = undefined">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="my-content md-content">
      <v-list>
        <v-list-item-group v-model="selected" color="primary">
          <template v-for="(item, i) in filteredItems">
            <v-list-item :key="filteredItems.indexOf(item)">
              <v-list-item-content>
                <v-list-item-title v-html="item.name"></v-list-item-title>
                <v-list-item-subtitle
                  v-html="item.activeTo4List"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="'dv_' + i"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import fetch from "../../utils/fetch";
import js2vm from "../../utils/js2vm";
import dateLocalFilter from "../../filters/dateLocal.filter";
import vm2js from "../../utils/vm2js";
import dateIsoFilter from "../../filters/dateIso.filter";
import rightFilter from '../../filters/right.filter';
import SaveButton from "@/components/ButtonFacade";

let Item = function(d) {
  let self = this;

  d.activeTo4List = d.activeTo

  self.activeTo2vm = function(val) {
    return dateIsoFilter(val);
  };

  self.activeTo4List2vm = function(val) {
    return dateLocalFilter(val);
  };

  js2vm(self, d);
};

export default {
  components: {SaveButton},
  data: () => ({
    rightFilter,
    selected: undefined,
    items: [],
    search: "",
    item: null,
    dialog: false,
    dialogDelete: false,
    editedItem: {}
  }),
  methods: {
    async fetch(item) {
      this.items = [];
      const items = await fetch.get(
        "/api/accounting/services/get-by-category?categoryId=" + item.id
      );
      items.forEach(i => {this.items.push(new Item(i))})
    },
    clearSearch() {
      this.search = "";
      this.selected = undefined;
    },
    addItem() {
      this.editedItem = new Item({categoryId: this.$parent.item.id})
      this.dialog = true
    },
    editItem() {
      this.editedItem = this.item;
      this.dialog = true;
    },

    deleteItem() {
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      const res = await fetch.post(
        "/api/accounting/services/delete-service?id=" + this.item.id
      );
      if (res) this.items.splice(this.selected, 1);
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = {}
    },

    async save() {
      const res = await fetch.post(
        "/api/accounting/services/save-service", vm2js(this.editedItem)
      );
      if (res) {
        this.editedItem.id = res
        if (this.selected !== undefined) {
          this.items[this.selected] = new Item(vm2js(this.editedItem));
        } else {
          this.items.push(this.editedItem);
        }
        this.editedItem = {}
        this.close();
      }
    }
  },
  watch: {
    "$parent.item": function(val) {
      this.fetch(val);
    },
    selected: function (val) {
      if (val === undefined) return this.item = null
      this.item = new Item(this.filteredItems[val]);
    }
  },
  computed: {
    filteredItems() {
      if (!this.search || this.search === null || 0 === this.search)
        return this.items;
      return _.orderBy(
        this.items.filter(item => {
          return (
            item.name &&
            item.name.toLowerCase().includes(this.search.toLowerCase())
          );
        }),
        "headline"
      );
    },
    formTitle() {
      return this.editedItem.id === undefined ? "Новая запись" : "Редактирование записи";
    }
  },
  mounted() {
    this.fetch(this.$parent.item);
  }
};
</script>
