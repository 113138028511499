<template>
<v-btn
icon
:disabled="disabled"
:loading="loading"
:color="color"
:text="!!content"

@click="handle"
>
  <v-icon v-if="!content">
    {{icon}}
  </v-icon>
  {{content}}
</v-btn>
</template>

<script>
export default {
  name: "ButtonFacade",
  props: {
    additionalRules: {
      type: Boolean,
      default: false
    },
    content : {
      type: String,
      default: undefined
    },
    icon: {
      type:String,
      default: "save"
    },
  color: {
      type: String,
    default: undefined
  },
    handler: Function
  },
  data() {
    return {
      clicked: false,
      loading: false
    }
  },
  computed: {
    disabled: function() {
      return this.clicked || (this.additionalRules)
    }
  },
  methods: {
    async handle() {
      this.clicked = true;
      this.loading=true;
      await this.handler();
      this.loading=false;
      this.clicked = false;

    }
  }
}
</script>

<style scoped>

</style>
