<template>
  <div class="view-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-text-field
          v-model="search"
          hide-details
          prepend-inner-icon="search"
          single-line
          dense
          filled
          rounded
          clearable
          @click:clear="clearSearch"
        ></v-text-field>
        <v-dialog
          v-if="rightFilter('w')"
          v-model="dialog"
          max-width="500px"
          scrollable
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.id"
                      readonly
                      label="id"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Наименование"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close">
                Отмена
              </v-btn>
              <SaveButton
              color="primary"
              content="OK"
              :handler="save"
              >

              </SaveButton>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-if="rightFilter('w')" icon :disabled="!item" @click="editItem">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
          v-if="rightFilter('d')"
          icon
          :disabled="!item"
          @click="deleteItem"
        >
          <v-icon>remove</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn icon color="primary" :loading="loading" @click="exp">
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Вы точно хотите удалить категорию?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeDelete">Отмена</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <div class="my-content md-content">
        <v-list>
          <v-list-item-group v-model="selected" color="primary">
            <template v-for="(item, i) in filteredItems">
              <v-list-item :key="filteredItems.indexOf(item)">
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="'dv_' + i"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <List v-if="selected != undefined" />
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import List from "../components/services/List";
import js2vm from "../utils/js2vm";
import vm2js from "../utils/vm2js";
import rightFilter from "../filters/right.filter";
import SaveButton from "@/components/ButtonFacade";

let Item = function(d) {
  let self = this;
  js2vm(self, d);
};

export default {
  data: () => ({
    rightFilter,
    selected: undefined,
    items: [],
    search: "",
    item: null,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      id: null,
      name: null
    },
    defaultItem: {
      id: null,
      name: null
    },
    loading: false
  }),
  methods: {
    async exp() {
      this.exporting = true;
      const res = await fetch.post(
        "/api/accounting/services/export",
        vm2js(this.filter)
      );
      if (res) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(await res.blob);
        link.download = res.filename;
        link.click();
      }
      this.exporting = false;
    },
    async fetch() {
      this.items = await fetch.get("/api/accounting/services/get-categories");
    },
    clearSearch() {
      this.search = "";
      this.selected = undefined;
    },
    editItem() {
      this.editedIndex = this.selected;
      this.editedItem = Object.assign({}, this.item);
      this.dialog = true;
    },

    deleteItem() {
      this.editedIndex = this.selected;
      this.editedItem = Object.assign({}, this.item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.closeDelete();
      const res = await fetch.post(
        "/api/accounting/services/delete-category?id=" + this.item.id
      );
      if (res) {
        this.search = "";
        this.selected = undefined;
        this.items = [];
        this.fetch()
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      const res = await fetch.post(
        "/api/accounting/services/save-category",
        vm2js(this.editedItem)
      );
      if (res) {
        this.editedItem.id = res;
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
        } else {
          this.items.push(this.editedItem);
        }
        this.close();
      }
    }
  },
  watch: {
    selected: function(val) {
      if (val === undefined) return (this.item = null);
      this.item = new Item(this.filteredItems[val]);
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  computed: {
    filteredItems() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.selected = undefined;
      if (!this.search || this.search === null || 0 === this.search.length)
        return this.items;
      return _.orderBy(
        this.items.filter(item => {
          return (
            item.name &&
            item.name.toLowerCase().includes(this.search.toLowerCase())
          );
        }),
        "headline"
      );
    },
    formTitle() {
      return this.editedIndex === -1 ? "Новая запись" : "Редактирование записи";
    }
  },
  mounted() {
    this.fetch();
  },
  components: {
    SaveButton,
    List
  }
};
</script>
